import React from "react";
import {BrowserRouter} from "react-router-dom";
import ReactGA from 'react-ga';
import Routes from "./routes/Routes";
import {ThemeProvider} from "@material-ui/core/styles";
import theme from "./theme";
import {I18nextProvider} from 'react-i18next';
import i18n from './i18n';

ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);

const App = () => (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <I18nextProvider i18n={i18n}>
          <Routes/>
        </I18nextProvider>
      </BrowserRouter>
    </ThemeProvider>
);


export default App;
