import React, { Suspense, lazy } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import CookieConsent from 'react-cookie-consent';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const HomePage = lazy(() => import('../pages/HomePage'));
const NotFoundPage = lazy(() => import('../pages/NotFoundPage'));
const SolutionsPage = lazy(() => import('../pages/Solutions'));

export default function Routes () {
  const { t } = useTranslation();
  const mobileMatch = useMediaQuery(({ breakpoints }) => breakpoints.up('sm'));

  const cookieConsentStyle = {
    backgroundColor: '#ffffff',
    color: '#000000',
    left: '50%',
    transform: 'translateX(-50%)',
    width: mobileMatch ? '65%' : '100%',
  };
  const cookieConsentBtnStyle = {
    backgroundColor: '#2b6ddc',
    color: '#ffffff',
    fontSize: mobileMatch ? 14 : 13,
  };

  return (
    <Suspense fallback={null}>
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route exact path="/solutions" component={SolutionsPage} />
        <Route exact path="/notfound" component={NotFoundPage} />
        <Redirect to="/" />
      </Switch>
      <CookieConsent
            buttonText={t('home.understand')}
            style={cookieConsentStyle}
            buttonStyle={cookieConsentBtnStyle}
        >
          {t('home.uses-cookies')}
        </CookieConsent>
    </Suspense>

  );
};
