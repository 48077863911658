import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import EN from './assets/locale/eng.json';
import RO from './assets/locale/rum.json';

const resources ={
  en: EN,
  ro: RO,
}

i18n
    .use(initReactI18next)
    .init({
      resources,
      lng: localStorage.getItem('lang') || 'en',
      fallbackLng: 'en'
    });

export default i18n;
