import {createTheme} from "@material-ui/core/styles";

const theme = createTheme({
  breakpoints: {
    keys: ["xs", "sm", "md", "lg", "xl"],
    values: {
      xs: 0,
      sm: 450,
      md: 768,
      lg: 1200,
      xl: 1600,
    },
  },
  palette: {
    primary: {
      main: "#2B6DDC",
      body: "#555770",
      light: "#F1F3F8",
      decor: "#98DE3C",
      text: "#7A7F8C",
    },
  },
  spacing: 8,
  typography: {
    fontFamily: ["Tahoma", "sans-serif"].join(","),
    h1: {
      fontSize: 28,
      lineHeight: "36px",
      fontWeight: 600,
      marginBottom: 28,
      color: "#FFFFFF",
      textAlign: "center",
      "@media (min-width: 450px)": {
        fontSize: 34,
      },
      "@media (min-width: 768px)": {
        fontSize: 54,
        lineHeight: "54px",
        marginBottom: 34,
      },
    },
    h2: {
      fontSize: 32,
      lineHeight: "44px",
      fontWeight: 600,
      // marginTop: 16,
      marginBottom: 29,
      color: "#1F4E78",
      "@media (max-width: 400px)": {
        fontSize: 24,
        lineHeight: "32px",
      },
    },
    h3: {
      fontSize: 24,
      lineHeight: "32px",
      fontWeight: 600,
      marginBottom: 16,
      color: "#1F4E78",
      "@media (max-width: 400px)": {
        fontSize: 20,
        lineHeight: "32px",
      },
    },
    h5: {
      fontSize: 20,
      fontWeight: 600,
      lineHeight: "28px",
      color: "#000000",
    },
    subtitle1: {
      fontSize: 12,
      lineHeight: "14px",
      textTransform: "uppercase",
      color: "#4F4F4F",
    },
    subtitle2: {
      fontSize: 14,
      lineHeight: "17px",
      letterSpacing: "2px",
      textTransform: "uppercase",
      color: "#555770",
      marginBottom: 8,
      "&::after": {
        content: "' '",
        display: "inline-flex",
        verticalAlign: "middle",
        width: "44px",
        height: "1px",
        background: "#2B6DDC",
        marginLeft: "16px",
      },
    },
  },

  overrides: {
    MuiAppBar:{
      root: {
        zIndex: 1400,
      },
    },
    MuiTypography: {
      root: {
        fontFeatureSettings: `"pnum" on, "lnum" on`,
      },
      body1: {
        fontSize: 16,
        lineHeight: "26px",
        color: "#555770",
      },
      body2: {
        fontSize: 14,
        lineHeight: "24px",
        color: "#333333",
      },
    },
    MuiButton: {
      root: {
        fontSize: 16,
        height: 49,
        lineHeight: "19px",
        textTransform: "uppercase",
        color: "#FFFFFF",
        backgroundColor: "#2B6DDC",
        borderRadius: 2,
        minWidth: 40,
      },
      contained: {
        width: "250px",
        color: "white",
        boxShadow: "none",
        backgroundColor: "rgba(255, 255, 255, 0.1)",
        border: "1px solid #FFFFFF",
        "&:hover": {
          backgroundColor: "rgba(255, 255, 255, 0.5)",
        },
      },
      text: {
        width: "100%",
        backgroundColor: "rgba(43, 109, 220, 0.1)",
        color: "#2B6DDC",
        padding: "15px 32px",
        fontSize: 16,
        lineHeight: "19px",
        letterSpacing: "1px",
        textTransform: "uppercase",
        "&:hover": {
          backgroundColor: "#2B6DDC",
          color: "#FFFFFF",
        },
      },
      outlined: {
        height: "36px",
        padding: "5px 10px",
        textTransform: "capitalize",
        backgroundColor: "#FFFFFF",
        color: "#2B6DDC",
        border: "1px solid #2B6DDC",
        "&:hover": {
          backgroundColor: "#2B6DDC",
          color: "#FFFFFF",
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        background: "#F8F9FA",
      },
      input: {
      },
    },
    MuiDivider: {
      root: {
        height: "2px",
        backgroundColor: "#555770",
        opacity: "0.2",
        margin: "20px",
      },
    },
    MuiTooltip: {
      // tooltip: {
      //   backgroundColor: "#E01E79",
      // },
      // arrow: {
      //   color: "#E01E79",
      // },
    },
  },
});

export default theme;
